<template>
  <div class="security-audit" @scroll="scrolling()">
    <breadcrumbs
        :breadcrumbs="breadcrumbs"
        :scroll="scroll"
        @goBack="back"
    />
    <section class="security-audit__banner">
      <img
          class="security-audit__banner-img"
          src="@/assets/cant-find/bg-cant-find@2x.jpg"
          alt="Case study: the client’s magento site was compromised"
          loading="lazy"
          modernize='sizes=["768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
      />
      <h1 class="security-audit__banner-title">
        CASE STUDY: THE CLIENT’S MAGENTO SITE WAS COMPROMISED
      </h1>
    </section>
    <div class="container blog-security-audit section_holder">
      <div class="section_title">
        <SidebarBlog :tagFromPost="tags" @searchByTag="goToPath"/>
      </div>
      <div class="section_content">
        <div class="section_content__item">
          <p>
            One of our clients recently launched their new e-commerce website, and due to the lack of an in-house dev team, was seriously concerned about possible website safety issues. Of course, each Magento webstore has its own custom specifics and configurations, however, if you don’t pay attention to conducting thorough and regular security checks, it can lead  to potential risks & data breaches. We trust that the shared case below, will serve as a good example of the importance of Magento security audit services, and the subsequent improved effectiveness.
            WDevs performed a complete site audit for our client, to analyze and  identify vulnerabilities, as well as improve overall website performance. During the testing for one of the tasks, we found a strange order description in the Magento admin panel:
          </p>
          <div class="security-audit__img">
            <img
                src="@/assets/blog/security-audit/security-audit-img-1.jpg"
                alt="Strange order description in the Magento admin panel"
                title=""
                loading="lazy"
                modernize='sizes=["480w", "768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
            />
          </div>
          <p>
            It seemed like someone tried to find a flaw in the existing forms, and subsequently injected  executable code, which could then be inserted into an email. We were unsure of whether this code injection had already done its dirty work, or not; nonetheless, we suggested that our client add additional form fields validations, to prevent hackers from inserting code into the form fields, and maliciously activating it in the future:
          </p>
          <ul>
            <li>The email field should contain only letters, numbers, and the @ sign to prevent injecting the scripts;</li>
            <li>Text fields should contain only the text - special characters will automatically be removed;</li>
            <li>Numerical fields can contain only numbers.</li>
          </ul>
          <p>
            Another, even more critical issue our team found, was related to the search process. It appeared that this security hole tolerated the possibility of executing malicious JS scripts on the page, and using Ajax:
          </p>
          <div class="security-audit__img">
            <img
                src="@/assets/blog/security-audit/security-audit-img-2.png"
                alt="The possibility of executing malicious JS scripts on the page, and using Ajax in the search process"
                title="The possibility of executing malicious JS scripts on the page, and using Ajax in the search process"
                loading="lazy"
                modernize='sizes=["480w", "768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
            />
          </div>
          <p>We also reviewed the Ajax request for Javascript and implemented the validation step for the search, as well as limiting the number of symbols that could be applied in the search.</p>
          <div class="security-audit__img">
            <img
                src="@/assets/blog/security-audit/security-audit-img-3.png"
                alt="Implementing a validation step for searches, as well as limiting the number of characters that can be used during a search"
                title="Implementing a validation step for searches, as well as limiting the number of characters that can be used during a search"
                loading="lazy"
                modernize='sizes=["480w", "768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
            />
          </div>
          <p>Here is the picture of the code which was used in the extension, before we applied validation:</p>
          <div class="security-audit__img">
            <img
                src="@/assets/blog/security-audit/security-audit-img-4.png"
                alt="The code that was used in the extension before validation"
                title="The code that was used in the extension before validation"
                loading="lazy"
                modernize='sizes=["480w", "768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
            />
          </div>
          <p>Unfortunately, the problem mentioned above was related to the insufficient security level in Magento itself. In combination with the unprotected search extension, it created a considerable security flaw that could be used to steal confidential data from the database.</p>
          <p>We traced a huge list of the queries in the database which had been exploited by the hacker, by entering them in the search field:</p>
          <div class="security-audit__img">
            <img
                src="@/assets/blog/security-audit/security-audit-img-5.png"
                alt="List of the queries in the database which had been exploited by the hacker"
                title="List of the queries in the database which had been exploited by the hacker"
                loading="lazy"
                modernize='sizes=["480w", "768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
            />
          </div>
          <p> This is what the result of one of the queries looked like:</p>
          <div class="security-audit__img">
            <img
                src="@/assets/blog/security-audit/security-audit-img-6.png"
                alt="View of one of the queries"
                title="View of one of the queries"
                loading="lazy"
                modernize='sizes=["480w", "768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
            />
          </div>
          <p>These kinds of queries can be very dangerous, and via them, malefactors can gain access to  parts of sites.
            This is why we integrated the security validation in the code - to prevent such situations and block any similar possible suspicious activity on the site.
            Given our 15+ years of Magento experience, we strongly recommend following these necessary steps after the validation of all forms (especially for search fields and  search requests). This is to ensure that all security breaches are closed:
          </p>

          <ul>
            <li>Change all passwords in the Magento admin panel and remove the users who should not have site admin access;</li>
            <li>Add 2FA for Magento admin login;</li>
            <li>Revise and remove access to SSH (allows access to SSH only using the key);</li>
            <li>Check GitHub repository access keys and remove the access where it is not necessary;</li>
            <li>Update the payment extension to the latest version;</li>
            <li>Change the Payment service API keys which are supposed to be used in the payment extension;</li>
            <li>Update Magento itself to the latest version.</li>
          </ul>

          <p>Magento security is one of the key elements for any e-commerce store. If you are the owner of a growing online business, you can imagine the possible legal implications and financial losses in the event of confidential information leaks, database hacking, and other malicious activities - as well as what it would do to your reputation.</p>

          <p>
            WDEVS can help audit the security level of your website, check the overall performance metrics, detect vulnerabilities, solve major issues, and safely update Magento.
            <a href="/magento#mg_services">Find out</a> how we can protect your site and  securely maintain your Magento webstore.
          </p>
        </div>

        <div>
          <div class="footer-blog-item">
            <div
                class="btn-read-more primary contacts-button small"
                id="show-modal"
                @click="this.$router.push('/blog')"
            >
              <span class="button-content">
                <span class="icon"
                ><img
                    v-svg-inline
                    :data-src="require('@/assets/icons/rarr-icon.svg')"
                    alt=""
                /></span>
                Read more our stories
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SidebarBlog from "./Sidebar.vue";
// import ModalContact from "../ModalContact.vue"
import { mapState } from "vuex";
import Breadcrumbs from "/src/components/Breadcrumbs";

export default {
  name: "BlogSecurityAudit",
  data() {
    return {
      backHistory:history.state.back,
      showModal:false,
      tags:['Magento Audit', 'eCommerce', 'Security Audit', 'Security Patches', 'Magento Services'],
      scroll: false,
      position: null,
      text: `
      Would you like to receive more examples of what we are able to help you achieve?<br />
      Do you still have doubts about your idea and believe that it will be too hard to implement?<br/>
      Would you like us to propose ways of turning your ideas into reality?
      `,
      breadcrumbs: [
        {
          link: '/',
          name: 'Home',
        },
        {
          name: 'Blog',
        },
        {
          name: 'Case study: the client`s magento site was compromised',
        },
      ],
    };
  },
  components: {
    SidebarBlog,
    Breadcrumbs,
  },
  computed:{
    ...mapState(['blog'])
  },
  methods: {
    goToPath(tag){
      let post = this.blog.find(item => item.tags.includes(tag))
      this.$router.push(post.img_src)
    },
    back(){
      if(!document.referrer || this.backHistory !== '/blog'){
        Object.defineProperty(document, "referrer", {
          get: function () {
            return `${window.location.origin}/blog`;
          },
        });
        this.$router.push('/blog')
        return
      } else{
        this.$router.go(-1)
      }
    },
    scrolling() {
      const scroll = document.documentElement.scrollTop;
      if (scroll > 50) {
        this.scroll = true;
      } else {
        this.scroll = false;
      }
    },
  },
  created() {
    console.log(history.state.back)
    // history.pushState('blog-home',window.location.href)
    window.history.scrollRestoration = "auto";
    window.addEventListener("scroll", this.scrolling);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.scrolling);
  },

};
</script>

<style lang="scss">
@import "../../scss/_variables.scss";
@import "include-media/dist/_include-media.scss";

.security-audit-header {
  .security-audit__title {
    padding: 120px 0;
    text-transform: uppercase;
    text-align: end;
    color: $black;
    font-size: 32px;
    line-height: 111%;
    margin: 0;
    @include media(">=tablet") {
      font-size: 40px;
      max-width: 100%;
    }
    @include media(">=tablet-xl") {
      font-size: 56px;
    }
    @include media(">=widescreen") {
      font-size: 72px;
      max-width: 100%;
    }
  }
}
.footer-blog-item {
  display: block;
  padding-top: 20px;
  @include media(">=tablet") {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .epr-tags {
    margin-top: 40px;
    color: $red;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    @include media(">=tablet") {
      margin-top: 0;
      text-align: center;
    }
    span {
      margin-right: 20px;
      @include media(">=widescreen") {
        margin-right: 40px;
      }
    }
  }
}

.security-audit {
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  a.outside_link {
    color: $black !important;
    font-style: italic;
  }
  .btn-read-more {
    .button-content {
      border-radius: 26px;
      padding: 2px 12px 3px;
      background-color: $red;
      color: $white;
      font-weight: 700;
      font-size: 16px;
      line-height: 28px;
      cursor: pointer;
      width: max-content;
      background-image: linear-gradient($darkRed, $darkRed);
      background-repeat: no-repeat;
      transition: background-size 0s, color 0s;
      background-size: 0 100%;
      @include media(">=tablet-xl") {
        padding: 6px 24px 8px;
      }
      &:hover {
        background-size: 100% 100%;
        color: $white;
      }
    }
    .icon {
      padding-top: 6px;
      margin-right: 15px;
      transform: rotateY(180deg);
    }
    a {
      color: $white;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .go-back-to-read {
    padding: 16px 0;
    border-bottom-left-radius: 26px 26px;
    border-bottom-right-radius: 26px 26px;
    position: fixed;
    background: $white;
    z-index: 50;
    width: 100vw;
    transition: all 0.3s;
    @include media(">=tablet-xl") {
      padding: 16px 0;
    }
    &.scrolling {
      padding: 5px 0;
      border-bottom: 1px solid $red;
      @include media(">=tablet-xl") {
        padding: 5px 0;
      }
    }
  }
}
.blog-security-audit {
  &.section_holder {
    display: flex;
    flex-direction: column-reverse;
    @include media(">=tablet-xl") {
      flex-direction: row;
    }
  }
  .section_title {
    margin-right: 0;
    width: 100%;
    @include media(">=tablet-xl") {
      margin-right: 43px;
      width: 35%;
    }
  }
  .section_content {
    margin-bottom: 60px;
    display: block;
    margin-left: 0;
    width: 100%;
    a {
      color: $red;
    }
    &__item {
      h5{
        font-size: 20px;
      }
      margin-bottom: 40px;
      @include media(">=tablet") {
        margin-bottom: 60px;
      }
      @include media(">=desktop") {
        margin-bottom: 120px;
      }
    }
    @include media(">=tablet-xl") {
      margin-left: 37px;
      width: 65%;
      margin-bottom: 0;
    }
  }
}

.security-audit__banner {
  height: 300px;
  position: relative;
  margin-bottom: 60px;
  @include media(">=tablet") {
    height: 500px;
  }
  @include media(">=desktop") {
    height: 600px;
    margin-bottom: 120px;
  }
  &-img,
  picture {
    display: block;
    height: 100%;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.security-audit__banner-title {
  text-transform: uppercase;
  color: $white;
  font-size: 32px;
  line-height: 111%;
  position: absolute;
  bottom: 20px;
  left: 16px;
  max-width: calc(100% - 32px);
  margin: 0;
  @include media(">=tablet") {
    bottom: 40px;
    font-size: 40px;
    left: 40px;
    max-width: calc(100% - 80px);
  }
  @include media(">=tablet-xl") {
    bottom: 80px;
    left: 40px;
    font-size: 56px;
    max-width: calc(100% - 80px);
  }
  @include media(">=widescreen") {
    bottom: 80px;
    left: 80px;
    font-size: 72px;
    max-width: 1150px;
  }
}
.security-audit__img {
  margin: 40px 0;
  display: block;
  picture {
    display: block;
  }
}
</style>
